<template>
  <div :class="type">
    <item-header
      :id="id"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :is-ygt-booking="isYgtBooking"
      :confirmation_reference_required="confirmation_reference_required"
      header_text="Flights"
    />

    <section class="section">
      <h3>Outward</h3>

      <p class="detail">
        {{ outwardDescriptionText }}
      </p>
      <p class="detail">
        {{ outwardDateText }}
      </p>
      <p class="detail">
        {{ outwardTimesText }}
      </p>
      <p class="detail">
        Flight Number: {{ outward_flight_number }}
      </p>
      <p class="detail">
        Reference: {{ outward_reference }}
      </p>
    </section>

    <section
      v-if="!isOneWay"
      class="section"
    >
      <h3>Inward</h3>

      <p class="detail">
        {{ inwardDescriptionText }}
      </p>
      <p class="detail">
        {{ inwardDateText }}
      </p>
      <p class="detail">
        {{ inwardTimesText }}
      </p>
      <p class="detail">
        Flight Number: {{ inward_flight_number }}
      </p>
      <p class="detail">
        Reference: {{ inward_reference }}
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>
      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { date, time } from 'lib/date-and-time-formats'
import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt
  },

  props: {
    ...commonProps,

    outward_origin: {
      type: String,
      default: null
    },

    outward_destination: {
      type: String,
      default: null
    },

    outward_date: {
      type: String,
      default: null
    },

    outward_departure_time: {
      type: String,
      default: null
    },

    outward_arrival_time: {
      type: String,
      default: null
    },

    outward_flight_number: {
      type: String,
      default: null
    },

    outward_reference: {
      type: String,
      default: null
    },

    inward_origin: {
      type: String,
      default: null
    },

    inward_destination: {
      type: String,
      default: null
    },

    inward_date: {
      type: String,
      default: null
    },

    inward_departure_time: {
      type: String,
      default: null
    },

    inward_arrival_time: {
      type: String,
      default: null
    },

    inward_flight_number: {
      type: String,
      default: null
    },

    inward_reference: {
      type: String,
      default: null
    }
  },

  computed: {
    outwardDateText () {
      return date(this.outward_date)
    },

    outwardDescriptionText () {
      return this.generateDescriptionText(
        this.outward_origin,
        this.outward_destination
      )
    },

    outwardTimesText () {
      return this.generateTimesText(
        this.outward_departure_time,
        this.outward_arrival_time
      )
    },

    isOneWay () {
      return !this.inward_date
    },

    inwardDateText () {
      return date(this.inward_date)
    },

    inwardDescriptionText () {
      return this.generateDescriptionText(
        this.inward_origin,
        this.inward_destination
      )
    },

    inwardTimesText () {
      return this.generateTimesText(
        this.inward_departure_time,
        this.inward_arrival_time
      )
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    }
  },

  methods: {
    generateDescriptionText (origin, destination) {
      return `${origin} to ${destination}`
    },

    generateTimesText (departureTime, arrivalTime) {
      return `Departs ${time(departureTime)} / Arrives ${time(arrivalTime)}`
    }
  }
}
</script>
