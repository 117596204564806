<template>
  <div :class="type">
    <item-header
      :id="id"
      :header_text="headerText"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :is-ygt-booking="isYgtBooking"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p class="detail">
        {{ passengerCountText }}
      </p>
      <p class="detail">
        {{ vehicleTypeText }}
      </p>
      <p
        v-if="tee_time && tee_time !== ''"
        class="detail"
      >
        Tee time: {{ tee_time }}
      </p>
    </section>

    <section class="section">
      <p class="detail">
        {{ outwardLocationsText }} {{ teeTimeOrArrivingFlightDetailsText }}
      </p>
      <p class="detail">
        Pick-up on
        <update-date-time
          v-if="dateTimeIsEditable"
          :date="outward_date"
          :time="outward_pick_up_time"
          date-input-class="outward-date"
          time-input-class="outward-time"
          :on-date-change="updateOutwardDate"
          :on-time-change="updateOutwardTime"
          :errors="getOutwardDateTimeErrors()"
          :required="pick_up_times_required"
        />
        <span v-else>
          {{ outwardDateText }} at {{ outwardTimeText }}
        </span>
      </p>
    </section>

    <section
      v-if="!isOneWay"
      class="section"
    >
      <p class="detail">
        {{ inwardLocationsText }} {{ departingFlightDetailsText }}
      </p>
      <p class="detail">
        Pick-up on
        <update-date-time
          v-if="dateTimeIsEditable"
          :date="inward_date"
          :time="inward_pick_up_time"
          date-input-class="inward-date"
          time-input-class="inward-time"
          :on-date-change="updateInwardDate"
          :on-time-change="updateInwardTime"
          :errors="getInwardDateTimeErrors()"
          :required="pick_up_times_required"
        />
        <span v-else>
          {{ inwardDateText }} at {{ inwardTimeText }}
        </span>
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>
      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { inflect } from 'inflection'
import { pick } from 'lodash'
import { date, optionalTime } from 'lib/date-and-time-formats'
import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import UpdateDateTime from './UpdateDateTime'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt,
    UpdateDateTime
  },

  props: {
    ...commonProps,

    transfer_type: {
      type: String,
      default: null
    },

    supplier_name: {
      type: String,
      default: null
    },

    count_pax: {
      type: Number,
      default: null
    },

    reference: {
      type: String,
      default: null
    },

    vehicle_type: {
      type: String,
      default: null
    },

    outward_pick_up_location: {
      type: String,
      default: null
    },

    outward_drop_off_location: {
      type: String,
      default: null
    },

    outward_date: {
      type: String,
      default: null
    },

    outward_pick_up_time: {
      type: String,
      default: null
    },

    outward_flight_number: {
      type: String,
      default: null
    },

    tee_time: {
      type: String,
      default: null
    },

    inward_pick_up_location: {
      type: String,
      default: null
    },

    inward_drop_off_location: {
      type: String,
      default: null
    },

    inward_date: {
      type: String,
      default: null
    },

    inward_pick_up_time: {
      type: String,
      default: null
    },

    inward_flight_number: {
      type: String,
      default: null
    },

    flight_arrival_time: {
      type: String,
      default: null
    },

    flight_departure_time: {
      type: String,
      default: null
    },

    isOneWay: {
      type: Boolean,
      required: true
    },

    pick_up_times_required: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    headerText () {
      return `Transfer for ${this.count_pax}` +
        ` ${inflect('passenger', this.count_pax)}`
    },

    passengerCountText () {
      return `${this.count_pax} ${inflect('passenger', this.count_pax)}` +
        ` (Reference: ${this.reference})`
    },

    vehicleTypeText () {
      return `Vehicle type: ${this.vehicle_type}`
    },

    outwardLocationsText () {
      return this.generateLocationsText(
        this.outward_pick_up_location,
        this.outward_drop_off_location
      )
    },

    outwardDateText () {
      return date(this.outward_date)
    },

    outwardTimeText () {
      return optionalTime(this.outward_pick_up_time)
    },

    inwardLocationsText () {
      return this.generateLocationsText(
        this.inward_pick_up_location,
        this.inward_drop_off_location
      )
    },

    teeTimeOrArrivingFlightDetailsText () {
      if (this.transfer_type === 'Golf') {
        if (this.tee_time && this.tee_time !== '') {
          return `(tee off ${this.tee_time})`
        }
      } else if (this.transfer_type === 'Airport') {
        if (this.outward_flight_number && this.outward_flight_number !== '') {
          return `(flight ${this.outward_flight_number}${this.getFlightArrivalTimeText})`
        } else {
          return '(flight *TBC*)'
        }
      }
      return ''
    },

    departingFlightDetailsText () {
      if (this.transfer_type === 'Airport') {
        if (this.inward_flight_number && this.inward_flight_number !== '') {
          return `(flight ${this.inward_flight_number}${this.getFlightDepartureTimeText})`
        } else {
          return '(flight *TBC*)'
        }
      }
      return ''
    },

    inwardDateText () {
      return date(this.inward_date)
    },

    inwardTimeText () {
      return optionalTime(this.inward_pick_up_time)
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    },

    dateTimeIsEditable () {
      return !this.originalData.confirmed &&
        (this.confirmed !== null && this.confirmed !== undefined)
    },

    getFlightArrivalTimeText () {
      return this.flight_arrival_time && this.flight_arrival_time !== ''
        ? `, arrives ${this.flight_arrival_time}`
        : ''
    },

    getFlightDepartureTimeText () {
      return this.flight_departure_time && this.flight_departure_time !== ''
        ? `, departs ${this.flight_departure_time}`
        : ''
    }
  },

  methods: {
    generateLocationsText (origin, destination) {
      return `${origin} to ${destination}`
    },

    getOutwardDateTimeErrors () {
      return pick(this.errors, ['outward_date', 'outward_pick_up_time'])
    },

    getInwardDateTimeErrors () {
      return pick(this.errors, ['inward_date', 'inward_pick_up_time'])
    },

    updateOutwardDate (value) {
      return this.updateAttribute(this.id, 'outward_date', value)
    },

    updateOutwardTime (value) {
      return this.updateAttribute(this.id, 'outward_pick_up_time', value)
    },

    updateInwardDate (value) {
      return this.updateAttribute(this.id, 'inward_date', value)
    },

    updateInwardTime (value) {
      return this.updateAttribute(this.id, 'inward_pick_up_time', value)
    }
  }
}
</script>
