<template>
  <div :class="type">
    <item-header
      :id="id"
      item-type="itemType"
      :header_text="course_name"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :is-ygt-booking="isYgtBooking"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p class="detail">
        {{ roundsForPlayersText }}
      </p>

      <p
        v-for="(teeTime, index) in tee_times"
        :key="index"
        class="detail"
      >
        {{ teeTimeText(teeTime) }}
        <update-time
          v-if="timeIsEditable"
          :date="teeTime.date"
          :time="teeTime.time"
          :on-change="updateTeeTime(id, index, 'tee_times')"
          :errors="teeTimeErrors(index)"
        />
        <span v-else>
          {{ teeTime.time }}
        </span>

        <span
          v-if="teeTime.booking_ref"
          class="block"
        >
          Booking Ref: {{ teeTime.booking_ref }}
        </span>

        <span
          v-if="isTakenFromAllocation"
          class="block from-allocation"
        >
          Taken from allocation
        </span>
      </p>

      <p
        v-if="buggies > 0"
        class="detail"
      >
        {{ buggiesDetailsText }}
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>

      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { inflect } from 'inflection'
import { get } from 'lodash'

import { date } from 'lib/date-and-time-formats'

import UpdateTime from './UpdateTime'

import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt,
    UpdateTime
  },

  props: {
    ...commonProps,

    count_pax: {
      type: Number,
      default: null
    },

    course_name: {
      type: String,
      default: null
    },

    rounds: {
      type: Number,
      default: null
    },

    tee_times: {
      type: Array,
      default: () => [],

      date: {
        type: String
      },

      time: {
        type: String
      },

      booking_ref: {
        type: String,
        default: null
      }
    },

    buggies: {
      type: Number,
      default: null
    },

    buggies_included: {
      type: Boolean,
      default: false
    },

    buggies_subject_to_availability: {
      type: Boolean,
      default: false
    },

    updateTimeAttribute: {
      type: Function,
      required: true
    }
  },

  computed: {
    teeTimesCount () {
      return this.tee_times.length
    },

    firstTeeTime () {
      return this.tee_times[0]
    },

    roundsForPlayersText () {
      return `${this.rounds} ${inflect('round', this.rounds)}` +
        ` for ${this.count_pax} ${inflect('player', this.count_pax)}`
    },

    buggiesText () {
      return `${this.buggies} ${inflect('buggy', this.buggies)}`
    },

    buggiesIncludedText () {
      if (this.buggies_subject_to_availability) {
        return `${this.buggiesText} complimentary (subject to availability)`
      } else {
        return `${this.buggiesText} requested`
      }
    },

    buggiesDetailsText () {
      if (this.buggies_included) {
        return this.buggiesIncludedText
      } else {
        return `${this.buggiesText} requested - payable locally`
      }
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    },

    timeIsEditable () {
      return this.originalData.confirmed == null &&
        (this.confirmed !== null && this.confirmed !== undefined)
    },

    isTakenFromAllocation () {
      const regex = /YGT[0-9]{5,}/

      return typeof (this.supplier_notes) === 'string' && regex.test(this.supplier_notes)
    }
  },

  methods: {
    teeTimeOnText (teeTime) {
      return `on ${date(teeTime.date)}`
    },

    teeTimeText (teeTime) {
      return `${this.numTeeTimesForPaxText(teeTime)} ${this.teeTimeOnText(teeTime)} at`
    },

    numTeeTimesForPaxText (teeTime) {
      return `${this.numberTeeTimesForPax(teeTime)} ` +
          `${inflect('tee time', this.numberTeeTimesForPax(teeTime))}`
    },

    numberTeeTimesForPax (teeTime) {
      if (teeTime.booking_ref) return 1

      return Math.ceil(this.count_pax / 4)
    },

    updateTeeTime (itineraryItemId, id, collectionName) {
      return (value) => {
        this.updateTimeAttribute(itineraryItemId, id, collectionName, value)
      }
    },

    teeTimeErrors (id) {
      return get(this.errors, `tee_times.${id}.time`)
    }
  }
}
</script>
