<template>
  <service-error
    v-if="error"
    :error="error"
    :is-ygt-booking="isYgtBooking"
  />

  <div v-else-if="verified">
    <header class="header main-header">
      <h2 class="subheader">
        Reservation form
      </h2>
      <h1
        v-if="suppliers"
        class="supplier-name"
      >
        {{ decodeString(supplierNames) }}
      </h1>
    </header>

    <booking-details
      v-if="booking && itineraryItems"
      :booking="booking"
      :itinerary-items="itineraryItems.active()"
      :is-ygt-booking="isYgtBooking"
    />

    <flash-messages
      :success="flash.success"
      :error="flash.error"
    />

    <div class="main">
      <p
        v-if="booking && booking.cancelled"
        class="booking-alert-notice"
      >
        Cancelled booking
      </p>

      <p
        v-else-if="bookingHasChanges"
        class="booking-alert-notice"
      >
        Amended booking
      </p>

      <div
        v-if="!isYgtBooking"
      >
        <itinerary-summary
          :itinerary-items="allItems"
        />
      </div>

      <div v-if="itineraryItems">
        <itinerary-items-list
          :itinerary-items="allItems"
        />
      </div>

      <loading v-else />

      <div v-if="suppliers && authorization && authorization.ids() && supplierPrices">
        <div
          v-for="id in authorization.ids()"
          :key="id"
        >
          <supplier-prices-list
            :supplier-name="suppliers.withId(id).name"
            :supplier-id="id"
            :supplier-prices="supplierPrices.pricesFor(id)"
            :supplier-price-totals="supplierPrices.totalsFor(id)"
            :has-net-rate="supplierPrices.hasNetRateFor(id)"
            :confirmed="supplierPrices.confirmedFor(id)"
            :rejection_reason="supplierPrices.rejectionReasonFor(id)"
            :confirmation_reference="supplierPrices.confirmationReferenceFor(id)"
            :confirmation_reference_required="supplierPrices.confirmationReferenceRequiredFor(id)"
            :update-attribute="updateSupplierPriceAttribute"
            :tbc_ok="supplierPrices.tbcOkFor(id)"
            :is-ygt-booking="isYgtBooking"
          />
        </div>
      </div>

      <loading v-else />

      <div v-if="invoiceTotal">
        <div class="invoice-rate">
          <header class="header item-header">
            <div class="core-detail">
              <h2>Invoice Total for Booking</h2>
            </div>
          </header>

          <div
            v-for="(price, index) in invoiceTotal"
            :key="index"
            class="supplier-price"
          >
            <div class="description">
              Total to invoice in {{ price.currency }}
            </div>
            <div class="total">
              {{ price.total }}&nbsp;{{ price.currency }}
            </div>
          </div>

          <footer v-if="isYgtBooking">
            Invoices should be emailed to <a :href="invoicesMailto">accounts@yourgolftravel.com</a>
          </footer>
        </div>
      </div>

      <loading v-else />

      <button
        :disabled="!canSubmitForm()"
        class="button submit-confirmations"
        @click="submitConfirmations"
      >
        Update confirmations
      </button>
    </div>
  </div>

  <loading v-else />
</template>

<script>
import ESignService from 'services/e-sign-service'
import ItineraryItems from 'collections/ItineraryItems'
import Authorization from 'collections/Authorization'
import Suppliers from 'collections/Suppliers'
import SupplierPrices from 'collections/SupplierPrices'

import Loading from 'components/LoadingComponent'
import ServiceError from 'components/errors/ServiceError'
import BookingDetails from './BookingDetails'
import FlashMessages from './FlashMessages'
import ItinerarySummary from './itinerary-items/ItinerarySummary'
import ItineraryItemsList from './itinerary-items/ListComponent'
import SupplierPricesList from './supplier-prices/ListComponent'

const eSignService = new ESignService()

export default {
  components: {
    Loading,
    ServiceError,
    BookingDetails,
    FlashMessages,
    ItinerarySummary,
    ItineraryItemsList,
    SupplierPricesList
  },

  props: {
    token: {
      type: String,
      required: true
    },

    supplier_id: {
      type: String,
      required: true
    },

    invalidation_group: {
      type: String,
      required: true
    },

    booking_reference: {
      type: String,
      required: true
    },

    timestamp: {
      type: String,
      required: true
    },

    booking_has_changes: {
      type: String,
      default: '0'
    }
  },

  data () {
    return {
      verified: false,
      suppliers: null,
      booking: null,
      invoiceTotal: null,
      itineraryItems: null,
      supplierPrices: null,
      submissionInProgress: false,
      error: null,
      flash: {
        success: null,
        error: null
      }
    }
  },

  computed: {
    authorization () {
      return Authorization.parse(
        this.supplier_id,
        this.invalidation_group,
        this.booking_reference,
        this.timestamp,
        this.token
      )
    },

    supplierNames () {
      return this.suppliers.names().join(', ')
    },

    bookingHasChanges () {
      return parseInt(this.booking_has_changes)
    },

    allItems () {
      if (this.itineraryItems === null) return []
      return this.itineraryItems.allItems()
    },

    activeItems () {
      if (this.itineraryItems === null) return []
      return this.itineraryItems.active()
    },

    isYgtBooking () {
      if ((this.booking === null || this.booking.brand_name === null) && this.booking_reference === null) return false

      if (this.booking !== null && this.booking.brand_name === 'YourGolfTravel') return true

      // When errors are thrown, we don't have access to the booking. The ref is the only thing we can check
      const re = /^[A-Z]{2}\d+/
      return re.test(this.booking_reference)
    },

    invoicesMailto () {
      return `mailto:accounts@yourgolftravel.com?subject=Invoice for booking reference ${this.booking.reference}`
    }
  },

  created () {
    this.authenticateAndFetchData()
  },

  methods: {
    authenticateAndFetchData () {
      return this.verifyToken()
        .then(() => {
          if (!this.error) {
            this.fetchSuppliers()
            this.fetchBooking()
            this.fetchItineraryItems()
            this.fetchSupplierPrices()
          }
        })
        .catch((error) => {
          this.error = error
        })
    },

    verifyToken () {
      if (this.authorization == null) {
        throw new Error('Parameters are invalid')
      }

      return eSignService
        .verifyHmacToken(this.authorization.struct())
        .then(response => {
          this.verified = true
        }).catch(error => {
          this.verified = false
          this.error = error
        })
    },

    fetchBooking () {
      return eSignService.fetchBooking(
        this.authorization.struct(),
        this.booking_reference
      ).then(response => {
        this.booking = response.data
        this.invoiceTotal = this.booking.invoice_total
      }).catch(error => {
        this.error = error
      })
    },

    fetchSuppliers () {
      return Suppliers
        .fetch(eSignService, this.authorization.struct(), this.authorization.ids())
        .then(suppliers => { this.suppliers = suppliers })
        .catch(error => { this.error = error })
    },

    fetchItineraryItems () {
      return ItineraryItems
        .fetch(this, eSignService, this.authorization.struct(), this.authorization.ids(), this.isYgtBooking)
        .then(items => { this.itineraryItems = items })
        .catch(error => { this.error = error })
    },

    fetchSupplierPrices () {
      return SupplierPrices
        .fetch(eSignService, this.authorization.struct(), this.booking_reference, this.authorization.ids(), this.isYgtBooking)
        .then(prices => { this.supplierPrices = prices })
        .catch(error => { this.error = error })
    },

    updateSupplierPriceAttribute (id, attribute, value) {
      this.supplierPrices.setAttributeFor(id, attribute, value)
      return value
    },

    canSubmitForm () {
      return this.itineraryItems &&
        this.supplierPrices &&
        this.itineraryItems.isValid() &&
        this.supplierPrices.isValid() &&
        !this.submissionInProgress
    },

    confirmationData () {
      const confirmations = this.suppliers.byId((supplierId, supplier) => {
        return {
          itinerary_items: this.itineraryItems.forSupplier(supplierId).asJSON(),
          supplier_prices: this.supplierPrices.structFor(supplierId),
          supplier
        }
      })

      return {
        booking: this.booking,
        confirmations
      }
    },

    decodeString (str) {
      const textArea = document.createElement('textarea')
      textArea.innerHTML = str
      return textArea.value
    },

    async submitConfirmations () {
      this.submissionInProgress = true

      await eSignService.updateConfirmations(
        this.authorization.struct(),
        this.booking_reference,
        this.confirmationData()
      ).then(response => {
        this.booking.revision = response.data.revision
        this.flash.success = 'Thank you we have updated our records'
      }).catch(err => {
        if (err.name === 'HTTPConflictError') {
          this.flash.error = 'The reservation form you submitted is out of' +
                             ' date. Please refresh the page and try again'
        } else {
          this.error = err
        }
      }).then(() => {
        this.authenticateAndFetchData()
        this.submissionInProgress = false
      })
    }
  }
}
</script>
