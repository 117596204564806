<template>
  <div :class="type">
    <item-header
      :id="id"
      :header_text="title"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :is-ygt-booking="isYgtBooking"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p
        v-for="line in descriptionTexts"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <section class="section">
      <ol
        v-if="lunchTimeTexts.length > 0"
        class="detail list"
      >
        <li
          v-for="lunchTimeText in lunchTimeTexts"
          :key="lunchTimeText"
        >
          {{ lunchTimeText }}
        </li>
      </ol>

      <ol
        v-if="treatmentTexts.length > 0"
        class="detail list"
      >
        <li
          v-for="treatmentText in treatmentTexts"
          :key="treatmentText"
        >
          {{ treatmentText }}
        </li>
      </ol>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>
      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <section
      v-if="guestLines.length > 0"
      class="guests"
    >
      <h4>Guests</h4>
      <p
        v-for="line in guestLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { inflect } from 'inflection'
import { date, optionalDateTime, optionalTime } from 'lib/date-and-time-formats'

import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt
  },

  props: {
    ...commonProps,

    package_description: {
      type: String,
      default: null
    },

    date: {
      type: String,
      default: null
    },

    lunch_times: {
      type: Array,
      default: () => []
    },

    treatments: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    title () {
      return 'Spa Package: ' + this.package_description.split('\n')[0]
    },

    descriptionTexts () {
      return this.package_description.split('\n').slice(1)
    },

    lunchTimeTexts () {
      return this.lunch_times.map(this.generateLunchTimeText)
    },

    treatmentTexts () {
      return this.treatments.map(this.generateTreatmentText)
    },

    supplierNotesLines () {
      if (!this.supplier_notes) return ''

      return this.supplier_notes.split(/\n/g)
    },

    guestLines () {
      if (!this.guests) return []

      return this.guests.map(
        guest => {
          const name = [guest.first_name, guest.surname].filter(Boolean).join(' ')
          const stock = guest.stock?.type === 'Protected' || guest.stock?.type === 'Committed'
            ? ' (' + guest.stock.type + ')'
            : ''
          return name + ': ' + guest.unit_type + stock
        }
      )
    }
  },

  methods: {
    generateLunchTimeText (lunchTime) {
      return `Lunch-time ${date(lunchTime.date)}` +
        ` at ${optionalTime(lunchTime.time)}`
    },

    generateTreatmentText (treatment) {
      const personCount = treatment.number_of_people

      return `${personCount} ${inflect('guest', personCount)}` +
        ` for ${treatment.description}` +
        ` ${optionalDateTime(treatment.date, treatment.time)}`
    }
  }
}
</script>
