<template>
  <header class="header item-header">
    <div class="core-detail">
      <h2>
        <span
          v-if="isCancelled"
          class="cancellation"
        >
          CANCELLATION -
        </span>

        {{ decodeString(header_text) }}
      </h2>
    </div>

    <p
      v-if="isCancelled && confirmed && hasConfirmationReference"
    >
      Confirmation Reference: {{ confirmation_reference }}
    </p>

    <confirmation-form
      v-if="!isCancelled"
      :id="id"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :confirmation-type="'confirmed'"
      :on-change="updateAttribute"
    />

    <confirmation-form
      v-else
      :id="id"
      :confirmed="deletion_confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :confirmation-type="'deletion_confirmed'"
      :on-change="updateAttribute"
    />

    <confirmation-reference-input
      v-if="!isCancelled && confirmed && hasConfirmationReference"
      :id="id"
      :item-type="itemType"
      :locked="locked"
      :confirmation_reference="confirmation_reference"
      :on-change="updateAttribute"
      :required="confirmation_reference_required"
      :errors="errors.confirmation_reference"
    />

    <rejection-reason-input
      v-if="!isCancelled && rejected"
      :id="id"
      :item-type="itemType"
      :locked="locked"
      :rejection_reason="rejection_reason"
      :on-change="updateAttribute"
      :is-ygt-booking="isYgtBooking"
      :errors="errors.rejection_reason"
    />
  </header>
</template>

<script>
import ConfirmationForm from './ConfirmationForm'
import ConfirmationReferenceInput from './ConfirmationReferenceInput'
import RejectionReasonInput from './RejectionReasonInput'

export default {
  name: 'ItemHeader',

  components: {
    ConfirmationForm,
    ConfirmationReferenceInput,
    RejectionReasonInput
  },

  props: {
    id: {
      type: String,
      required: true
    },

    itemType: {
      type: String,
      default: 'itinerary-item'
    },

    header_text: {
      type: String,
      default: null
    },

    confirmed: {
      type: Boolean,
      default: null
    },

    locked: {
      type: Boolean,
      default: false
    },

    tbc_ok: {
      type: Boolean,
      default: true
    },

    deletion_confirmed: {
      type: Boolean,
      default: null
    },

    confirmation_reference: {
      type: String,
      default: ''
    },

    rejection_reason: {
      type: String,
      default: ''
    },

    updateAttribute: {
      type: Function,
      required: true
    },

    hasConfirmationReference: {
      type: Boolean,
      default: true
    },

    deleted_at: {
      type: String,
      default: null
    },

    errors: {
      type: Object,
      default: () => ({})
    },

    confirmation_reference_required: {
      type: Boolean,
      default: false
    },

    isYgtBooking: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    rejected () {
      return this.confirmed === false
    },

    isCancelled () {
      return !!this.deleted_at
    }
  },

  methods: {
    decodeString (str) {
      const textArea = document.createElement('textarea')
      textArea.innerHTML = str
      return textArea.value
    }
  }
}
</script>
