<template>
  <div class="rejection-reason">
    <label
      :for="labelFor"
      class="label"
    >
      Rejection reason
      <span class="sub-label required">
        (required to continue)
      </span>
    </label>

    <textarea
      :id="labelFor"
      :class="{ error: shouldDisplayError }"
      :value="rejection_reason"
      :disabled="locked"
      class="input"
      @input="updateRejectionReason"
      @blur="removeFocus"
    />

    <p class="printable-version">
      {{ rejection_reason }}
    </p>

    <p
      v-for="error in errorMessages"
      :key="error"
      class="input-error"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'RejectionReasonInput',

  props: {
    id: {
      type: String,
      required: true
    },

    itemType: {
      type: String,
      required: true
    },

    locked: {
      type: Boolean,
      default: false
    },

    rejection_reason: {
      type: String,
      default: ''
    },

    errors: {
      type: Array,
      default: () => []
    },

    onChange: {
      type: Function,
      required: true
    },

    isYgtBooking: {
      type: Boolean,
      default: false
     }
  },

  data () {
    return {
      hasBeenFocussedOn: false
    }
  },

  computed: {
    labelFor () {
      return `rejection_reason_${this.id}`
    },

    errorMessages () {
      return this.errors.map(e => {
        switch (e) {
          case 'blank':
            if (this.itemType === 'supplier-prices' && this.isYgtBooking) {
              return 'Rejection reason is required. Please provide full details of the cost disputed. Please also provide your name as a reference.'
            }
            else {
              return 'Rejection reason is required when rejecting.'
            }
          default:
            return e
        }
      })
    },

    shouldDisplayError () {
      return this.errors.length > 0
    }
  },

  methods: {
    removeFocus (event) {
      this.updateRejectionReason(event)
      this.setFocussedOn()
    },

    setFocussedOn () {
      this.hasBeenFocussedOn = true
    },

    updateRejectionReason (event) {
      return this.onChange(this.id, 'rejection_reason', event.target.value)
    }
  }
}
</script>
