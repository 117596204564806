<template>
  <div class="invoice-rate">
    <item-header
      :id="supplierId"
      :item-type="itemType"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :has-confirmation-reference="hasConfirmationReference"
      :header_text="headingTitle"
      :errors="errors"
      :is-ygt-booking="isYgtBooking"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <ol class="list supplier-prices">
      <show-component
        v-for="item in supplierPrices"
        :key="item.id"
        v-bind="item"
      />

      <li
        v-for="(supplierPrice, index) in supplierPriceTotals"
        :key="index"
        class="list-item supplier-price"
      >
        <div class="description">
          Supplier subtotal in {{ supplierPrice.currency }}
        </div>

        <div class="total">
          {{ supplierPrice.total }} {{ supplierPrice.currency }}
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import ShowComponent from './ShowComponent'
import ItemHeader from '../ItemHeader'

export default {
  components: {
    ShowComponent,
    ItemHeader
  },

  props: {
    supplierName: {
      type: String,
      required: true
    },

    supplierId: {
      type: String,
      required: true
    },

    supplierPrices: {
      type: Array,
      required: true
    },

    supplierPriceTotals: {
      type: Array,
      required: true
    },

    confirmed: {
      type: Boolean,
      default: false
    },

    locked: {
      type: Boolean,
      default: false
    },

    tbc_ok: {
      type: Boolean,
      default: true
    },

    hasNetRate: {
      type: Boolean,
      default: false
    },

    confirmation_reference: {
      type: String,
      default: ''
    },

    confirmation_reference_required: {
      type: Boolean,
      required: true
    },

    rejection_reason: {
      type: String,
      default: ''
    },

    updateAttribute: {
      type: Function,
      required: true
    },

    isYgtBooking: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    headingTitle () {
      const rate_text = this.hasNetRate === true
        ? 'Net rate inclusive of VAT for '
        : 'Invoice Rate for '

      return rate_text + this.supplierName
    },

    hasConfirmationReference () {
      return this.isYgtBooking
    },

    isRejected () {
      return this.confirmed === false
    },

    isConfirmed () {
      return this.confirmed === true
    },

    itemType () {
      return 'supplier-prices'
    },

    errors () {
      if (this.isRejected) {
        if (!this.rejection_reason || this.rejection_reason === '') {
          return { rejection_reason: ['blank'] }
        }
      } else if (this.isConfirmed) {
        if (!this.confirmation_reference || this.confirmation_reference === '') {
          return { confirmation_reference: ['blank'] }
        }
      }

      return {}
    }
  }
}
</script>
