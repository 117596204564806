<template>
  <div :class="type">
    <item-header
      :id="id"
      :header_text="title"
      :confirmed="confirmed"
      :locked="locked"
      :tbc_ok="tbc_ok"
      :deletion_confirmed="deletion_confirmed"
      :confirmation_reference="confirmation_reference"
      :rejection_reason="rejection_reason"
      :update-attribute="updateAttribute"
      :deleted_at="deleted_at"
      :errors="errors"
      :is-ygt-booking="isYgtBooking"
      :confirmation_reference_required="confirmation_reference_required"
    />

    <section class="section">
      <p class="detail">
        {{ numberNightsForGuestsText }}
      </p>

      <p class="detail">
        {{ checkInAtText }}
      </p>

      <p class="detail">
        {{ checkOutAtText }}
      </p>

      <p
        v-if="dinner_time"
        class="detail"
      >
        {{ dinnerTimeText }}
      </p>

      <ol
        v-if="guestsPerRoomTypeTexts.length > 0"
        class="detail list"
      >
        <li
          v-for="guestPerRoomTypeText in guestsPerRoomTypeTexts"
          :key="guestPerRoomTypeText"
        >
          {{ guestPerRoomTypeText }}
        </li>
      </ol>

      <p
        v-if="buggies > 0"
        class="detail"
      >
        {{ buggiesDetailsText }}
      </p>

      <p
        v-for="(extra, index) in extras"
        :key="index"
        class="detail"
      >
        <span v-if="extra.description">
          {{ extra.description }}
        </span>
        <span v-if="extra.comment">
          ({{ extra.comment }})
        </span>
      </p>
    </section>

    <section
      v-if="component_status === 'ALLOCATION'"
      class="detail"
    >
      <p class="from-allocation">
        Taken from allocation
      </p>
    </section>

    <section
      v-if="supplier_notes !== ''"
      class="supplier-notes"
    >
      <h4>Notes</h4>
      <p
        v-for="line in supplierNotesLines"
        :key="line"
      >
        {{ line }}
      </p>
    </section>

    <deleted-at
      :deleted_at="deleted_at"
    />
  </div>
</template>

<script>
import { groupBy, flatMap } from 'lodash'
import { inflect } from 'inflection'
import moment from 'moment'

import { date, time } from 'lib/date-and-time-formats.js'
import ItemHeader from '../ItemHeader'
import DeletedAt from '../DeletedAt'
import commonProps from './common-props'

export default {
  components: {
    ItemHeader,
    DeletedAt
  },

  props: {
    ...commonProps,

    count_pax: {
      type: Number,
      default: null
    },

    hotel_name: {
      type: String,
      default: null
    },

    branded_header: {
      type: String,
      default: null
    },

    check_in_date: {
      type: String,
      default: null
    },

    check_in_time: {
      type: String,
      default: null
    },

    check_out_time: {
      type: String,
      default: null
    },

    dinner_time: {
      type: String,
      default: null
    },

    nights: {
      type: Number,
      default: null
    },

    board_basis: {
      type: Object,

      default () {
        return {
          description: null
        }
      },

      description: {
        type: String
      }
    },

    rooms: {
      type: Array,
      default: () => [],

      occupants: {
        type: Array
      }
    },

    buggies: {
      type: Number,
      default: null
    },

    buggies_included: {
      type: Boolean,
      default: false
    },

    component_status: {
      type: String,
      default: null
    },

    extras: {
      type: Array,
      default: () => [],

      description: {
        type: String
      },

      comment: {
        type: String
      }
    }
  },

  computed: {
    title () {
      return this.branded_header
    },

    roomsByTypeAndGuestCount () {
      return flatMap(
        this.groupRoomsByStock(this.rooms),
        rooms => flatMap(
          this.groupRoomsByType(rooms),
          rooms => this.groupRoomsByGuestCount(rooms)
        )
      )
    },

    checkOutDate () {
      return moment(this.check_in_date).add(this.nights, 'days')
    },

    numberNightsForGuestsText () {
      return `${this.nights} ${inflect('night', this.nights)}` +
        ` ${this.board_basis.description} for` +
        ` ${this.count_pax} ${inflect('guests', this.count_pax)}`
    },

    checkInAtText () {
      return `Check-in ${date(this.check_in_date)}` +
        `${this.checkInTimeText(this.check_in_time)}`
    },

    checkOutAtText () {
      return `Check-out ${date(this.checkOutDate)}` +
        `${this.checkOutTimeText(this.check_out_time)}`
    },

    dinnerTimeText () {
      return `Dinner-time ${time(this.dinner_time)}`
    },

    guestsPerRoomTypeTexts () {
      return this.roomsByTypeAndGuestCount
        .map(this.generateGuestPerRoomTypeText)
    },

    supplierNotesLines () {
      return this.supplier_notes.split(/\n/g)
    },

    buggiesText () {
      return `${this.buggies} ${inflect('buggy', this.buggies)}`
    },

    buggiesDetailsText () {
      if (this.buggies_included) {
        return `${this.buggiesText} requested`
      } else {
        return `${this.buggiesText} requested - payable locally`
      }
    }
  },

  methods: {
    generateGuestPerRoomTypeText (rooms) {
      const count = rooms.length
      const type = rooms[0].type
      const occupantCount = rooms[0].occupants.length
      const stock = this.stock(rooms[0])
      const stockText = stock ? `, ${stock} Stock` : ''

      let roomText = `${count} ${type} ${inflect('rooms', count)}`
      if (occupantCount > 0) roomText += ` (${occupantCount} ${inflect('guests', occupantCount)} per room${stockText})`
      return roomText
    },

    groupRoomsByType (rooms) {
      return Object.values(groupBy(rooms, 'type'))
    },

    groupRoomsByGuestCount (rooms) {
      return Object.values(groupBy(rooms, room => room.occupants.length))
    },

    groupRoomsByStock (rooms) {
      return Object.values(groupBy(rooms, room => this.stock(room)))
    },

    stock (room) {
      return room.stock?.type === 'Protected' || room.stock?.type === 'Committed'
        ? room.stock.type
        : null
    },

    checkInTimeText (check_in_time) {
      return check_in_time !== null ? ` after ${time(check_in_time)}` : ''
    },

    checkOutTimeText (check_out_time) {
      return check_out_time !== null ? ` before ${time(check_out_time)}` : ''
    }
  }
}
</script>
