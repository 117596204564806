import HotelItineraryItem from '../models/HotelItineraryItem'
import ItineraryItem from '../models/ItineraryItem'
import GolfItineraryItem from '../models/GolfItineraryItem'
import TransferItineraryItem from '../models/TransferItineraryItem'

// Monkey patch asJSON into the array which will
// return a new array, running asJSON on each item
const jsonAble = (array) => {
  array.asJSON = () => { return array.map(item => item.asJSON()) }
  return array
}

export default class ItineraryItems {
  constructor (data, container, isYgtBooking) {
    this.isYgtBooking = isYgtBooking
    this.ids = Object.keys(data)
    this.data = data
    this.container = container
    this.ids.forEach((id) => {
      const contents = data[id]
      data[id] = contents.map(this.toItineraryItem())
    })
  }

  forSupplier (supplierId) {
    return jsonAble(this.data[supplierId])
  }

  allItems () {
    // Flattens the array (Array#flat() is not in the ECMA spec)
    return jsonAble([].concat.apply([], Object.values(this.data)))
  }

  active () {
    return jsonAble(this.allItems().filter(({ deleted_at }) => !deleted_at))
  }

  cancelled () {
    return jsonAble(this.allItems().filter(({ deleted_at }) => deleted_at))
  }

  isValid () {
    return this.allItems().every(item => item.isValid())
  }

  find (id) {
    return this.allItems().find(item => item.id === id)
  }

  // Returns a function that transforms data to an Itinerary Item
  toItineraryItem () {
    return (data) => {
      switch (data.type) {
        case 'golf':
          return new GolfItineraryItem(data, this.container, this.isYgtBooking)
        case 'hotel':
          return new HotelItineraryItem(data, this.container, this.isYgtBooking)
        case 'transfers':
          return new TransferItineraryItem(data, this.container, this.isYgtBooking)
        default:
          return new ItineraryItem(data, this.container, this.isYgtBooking)
      }
    }
  }
}

ItineraryItems.fetch = (container, eSignService, authorization, supplierIds, isYgtBooking) => {
  const promises = supplierIds.map(supplierId => {
    return eSignService.fetchItineraryItems(
      authorization, authorization.booking_reference, supplierId
    )
  })
  return Promise.all(promises).then(responses => {
    const supplierItems = responses.map(response => {
      return response.data
    })
    const items = {}
    for (let i = 0; i < supplierIds.length; i++) {
      items[supplierIds[i]] = supplierItems[i]
    }
    return new ItineraryItems(items, container, isYgtBooking)
  })
}
