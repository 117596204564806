<template>
  <div class="summary">
    <header class="header item-header">
      <div class="core-detail">
        <h2>
          Booking Summary
        </h2>
      </div>
    </header>
    <div v-if="roomSummaries.length > 0">
      <h3>Rooms</h3>
      <ol class="list summary">
        <li
          v-for="room in roomSummaries"
          :key="room.id"
          class="list-item"
          :class="{ cancelled: room.cancelled }"
        >
          {{ room.summary }}
        </li>
      </ol>
    </div>
    <div v-if="packageSummaries.length > 0">
      <h3>Packages</h3>
      <ol class="list summary">
        <li
          v-for="packageData in packageSummaries"
          :key="packageData.id"
          class="list-item"
          :class="{ cancelled: packageData.cancelled }"
        >
          {{ packageData.summary }}
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    itineraryItems: {
      type: Array,
      required: true
    }
  },

  computed: {
    roomSummaries () {
      const isHotelItem = item => item.type === 'hotel'
      const rooms = item => item.rooms.map(
        room => {
          return {
            id: item.id,
            cancelled: !!item.deleted_at,
            summary: roomText(item, room)
          }
        }
      )
      const roomText = (item, room) => {
        return this.cancellationText(item) + room.type + ': ' +
          this.pluralize(room.occupants.length, 'occupant') +
          ', ' + this.pluralize(item.nights, 'night')
      }

      const summaries = this.itineraryItems.filter(isHotelItem).flatMap(rooms)
      return summaries
    },

    packageSummaries () {
      const isSpaItem = item => item.type === 'spa'
      const packages = item => {
        return {
          id: item.id,
          cancelled: !!item.deleted_at,
          summary: packageText(item)
        }
      }
      const packageText = (item) => {
        return this.cancellationText(item) + packageTitle(item) + ': ' +
          this.pluralize(item.treatments.length, 'treatment')
      }
      const packageTitle = (item) => item.package_description.split('\n')[0]

      const summaries = this.itineraryItems.filter(isSpaItem).map(packages)
      return summaries
    }
  },

  methods: {
    cancellationText (item) {
      return item.deleted_at ? 'CANCELLATION ' : ''
    },

    pluralize (num, word) {
      if (num === 0) {
        num = 'no'
      }

      return '' + num + ' ' + word + (num !== 1 ? 's' : '')
    }
  }
}
</script>
