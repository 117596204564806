<template>
  <div class="confirmation-reference">
    <label
      :for="labelFor"
      class="label"
    >
      Your reference
      <span
        v-if="required"
        class="sub-label required"
      >
        (required to continue)
      </span>
      <span
        v-else-if="!locked"
        class="sub-label"
      >
        (optional)
      </span>
    </label>

    <input
      :id="labelFor"
      :value="confirmation_reference"
      :disabled="locked"
      :class="{ error: shouldDisplayError }"
      class="input"
      type="text"
      @input="updateConfirmationReference"
      @blur="removeFocus"
    >

    <template v-if="hasBeenFocussedOn || required">
      <p
        v-for="error in errorMessages"
        :key="error"
        class="input-error"
      >
        {{ error }}
      </p>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ConfirmationReferenceInput',

  props: {
    id: {
      type: String,
      required: true
    },

    itemType: {
      type: String,
      required: true
    },

    locked: {
      type: Boolean,
      default: false
    },

    required: {
      type: Boolean,
      default: false
    },

    confirmation_reference: {
      type: String,
      default: ''
    },

    errors: {
      type: Array,
      default: () => []
    },

    onChange: {
      type: Function,
      required: true
    }
  },

  data () {
    return {
      hasBeenFocussedOn: false
    }
  },

  computed: {
    labelFor () {
      return `confirmation_reference_${this.id}`
    },

    errorMessages () {
      return this.errors.map(e => {
        switch (e) {
          case 'blank':
            if (this.itemType === 'itinerary-item') {
              return 'Confirmation reference number or name is required when confirming - DO NOT ENTER ANY COST INFORMATION IN THIS FIELD.'
            } else {
              return 'Confirmation reference number or name required. You are agreeing to the total supplier cost listed below.'
          }
          default:
            return e
        }
      })
    },

    shouldDisplayError () {
      return this.errors.length > 0 && (this.required || this.hasBeenFocussedOn)
    }
  },

  methods: {
    removeFocus (event) {
      this.updateConfirmationReference(event)
      this.setFocussedOn()
    },

    setFocussedOn () {
      this.hasBeenFocussedOn = true
    },

    updateConfirmationReference (event) {
      return this.onChange(this.id, 'confirmation_reference', event.target.value)
    }
  }
}
</script>
